<template>
  <b-modal
    modal-class="modal_basic customCode_modal"
    id="customCode"
    ref="custom_widget_modal"
    centered
    no-close-on-backdrop
    hide-footer
    hide-header
  >
    <div class="w-full h-full">

      <div
        class="w-full h-full flex flex-col items-center justify-between pt-2 gap-6"
      >
        <div class="flex flex-col items-center justify-center">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.4445 2H5.55556C3.59188 2 2 3.59188 2 5.55556V30.4445C2 32.4082 3.59188 34.0001 5.55556 34.0001H30.4445C32.4082 34.0001 34.0001 32.4082 34.0001 30.4445V5.55556C34.0001 3.59188 32.4082 2 30.4445 2Z"
              stroke="#2961D2"
              stroke-width="2.74298"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2 12.667H34.0001"
              stroke="#2961D2"
              stroke-width="2.74298"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.6667 34.0004V12.667"
              stroke="#2961D2"
              stroke-width="2.74298"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p
            class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5"
          >
            {{
              getCustomWidgetAdd.preview
                ? "Preview Custom Widget Code"
                : "Add Custom Widget Code"
            }}
          </p>

          <p class="pt-1 text !text-[14px] text-center">
            Please enter details to add custom widget code.
          </p>
        </div>

        <div
          class="flex flex-col justify-center item-center w-full gap-3"
          v-if="!loader"
        >
          <FloatingLabelInput
            v-if="!getCustomWidgetAdd.preview"
            id="Name"
            @enter="validateAndStorePixel()"
            :errorType="
              validations.name || validations.nameLength ? 'danger' : ''
            "
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="Name"
            placeholder="Enter name of code block"
            v-model="getCustomWidgetAdd.name"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="validations.name"
                :message="messages.name"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="validations.nameLength"
                :message="messages.nameLength"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>

          <div
            class="flex flex-col justify-start item-start w-full"
            :class="{
              'input-field-error':
                validations.javascript || validations.javascriptLength,
            }"
          >
            <p class="text-[16px] font-bold pb-3">Custom JavaScript</p>
            <code-editor
              v-model="getCustomWidgetAdd.js"
              class="custom-code-input !rounded-[8px] !bg-[#3C4549] text-white"
              mode="javascript"
              theme="chrome"
            />
            <span class="input-error" v-if="validations.javascript">{{
                messages.javascript
              }}</span>
            <span class="input-error" v-if="validations.javascriptLength">{{
                messages.javascriptLength
              }}</span>
          </div>

          <div
            class="flex flex-col justify-start item-start w-full text-white"
            :class="{
              'input-field-error': validations.html || validations.htmlLength,
            }"
          >
            <p class="text-[16px] font-bold pb-3">Custom HTML</p>
            <code-editor
              v-model="getCustomWidgetAdd.html"
              class="custom-code-input !rounded-[8px] !bg-[#3C4549]"
              mode="html"
              theme="chrome"
            />
            <template v-if="getCustomWidgetAdd.html">
              <template v-if="!getCustomWidgetAdd.preview">
                <template>
                  <div class="flex flex-col justify-start items-start gap-1">
                    <div
                      class="flex items-center justify-between border-b border-[#ECEEF5] min-h-[4.57rem] w-full"
                    >
                      <p
                        class="text text-center font-poppins text-base font-normal"
                      >
                        When should it appear?
                      </p>

                      <div
                        class="flex justify-between item-center "
                      >
                        <Checkbox
                          id="exit_intent-checkbox"
                          v-model="CheckBoxOne"
                          label="On Exit-intent"
                          type="checkbox"
                          @change="checkCheckBoxes('CheckBoxOne')"
                          labelClass="text-[#757A8A] mr-4"
                          inputValue="exit_intent"
                          :isDisabled="false"
                        ></Checkbox>

                        <div
                          class="inline-flex items-center gap-1 justify-end"
                        >
                          <Checkbox
                            id="after-checkbox"
                            v-model="CheckBoxTwo"
                            @change="checkCheckBoxes('CheckBoxTwo')"
                            label="After 'X' second."
                            type="checkbox"
                            labelClass="text-[#757A8A]"
                            customCheckBoxClass="h-5"
                            :isDisabled="false"
                            inputValue="time"
                          ></Checkbox>
                          <input
                            v-model="getCustomWidgetAdd.trigger_seconds"
                            v-if="CheckBoxTwo"
                            class="inline_input text-black border-b border-[#ECEEF5] w-auto max-w-[5.5rem]"
                            type="number"
                            min="0"
                          />

                          <p
                            class="text text-center font-poppins text-base font-normal"
                            v-if="CheckBoxTwo"
                          >
                            seconds
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex flex-row items-center justify-between border-b border-[#ECEEF5] min-h-[4.57rem] w-full"
                    >
                      <p
                        class="text text-center font-poppins text-base font-normal"
                      >
                        Where should it appear?
                      </p>

                      <div class="!w-[13.42rem]">
                        <!--                        <Selectdropdown-->
                        <!--                          :showDefaultContent="false"-->
                        <!--                          v-model="getCustomWidgetAdd.position"-->
                        <!--                          id="company"-->
                        <!--                          :options="positionsList"-->
                        <!--                          CustomClass=" border !border-transparent font-poppins text  !bg-[#F4F6FA] h-[33px]  !focus:border-blue-500 !active:border-blue-500 !active:ring-blue-500 w-full "-->
                        <!--                        >-->
                        <!--                          <template v-slot:label>-->
                        <!--                            <label-->
                        <!--                              for="deep-link-support"-->
                        <!--                              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"-->
                        <!--                              >Position</label-->
                        <!--                            >-->
                        <!--                          </template>-->
                        <!--                        </Selectdropdown>-->

                        <b-dropdown
                          id="company"
                          ref="position_model_menu"
                          right
                          class="w-full dropdown-menu-right hide_dropdown_caret  default_style_dropdown"
                          :no-caret="true"
                        >
                          <div
                            class=" justify-between px-[0.9rem] h-[31px] bg-[#F4F6FA] rounded-[8px] items-center gap-2.5 flex !w-full"
                            slot="button-content">

                            <div class="w-full flex justify-between items-center">
                              <div>
                                <p class="text-[14px] text font-normal font-poppins">
                                  {{ getCustomWidgetAdd.position }}</p>
                              </div>
                              <span class="arrow_icon ml-auto"><i
                                class="text-[#757A8A] font-semibold fal fa-angle-down ml-3"></i></span>
                            </div>
                          </div>
                          <transition name="fade">
                            <ul
                              class="w-full text-[#202324] py-1 text-[0.875rem] shadow-lg bg-white rounded-lg font-poppins">
                              <template
                                v-for="position in positionsList">
                                <li
                                  v-model="getCustomWidgetAdd.position"
                                  class="list_item_li !hover:bg-[#F4F6FA] !py-3"
                                  @click="getCustomWidgetAdd.position = position.value; $refs.position_model_menu.hide(true) "
                                >
                                  <div class="flex items-center ">
                                    <div class="content">
                                      <div class="text">
                                        {{ position.label }}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </template>
                            </ul>

                          </transition>
                        </b-dropdown>


                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </template>
            <span class="input-error" v-if="validations.html">{{
                messages.html
              }}</span>
            <span class="input-error" v-else-if="validations.htmlLength">{{
                messages.htmlLength
              }}</span>
          </div>
        </div>

        <div
          class="flex flex-col justify-center items-center mt-3 w-full"
          v-if="loader"
        >
          <clip-loader :size="'26px'" :color="'#1d4ed8'"></clip-loader>
        </div>

        <div class="mt-3  mb-[1.14rem] flex gap-x-3 justify-between items-center">
          <Button
            id="close-button"
            type="button"
            class="!border-[1.5px] !border-[#3C4549] !py-[1.142rem] !rounded-[0.571rem] hover:bg-[#F2F3F8]"
            buttonClass="btn-lg"
            @click="close()"
          >
            <template v-slot:label>Close</template>
          </Button>

          <template v-if="!getCustomWidgetAdd.preview">
            <button
              v-if="getCustomWidgetAdd.id"
              @click.prevent="previewWidget([getCustomWidgetAdd.id])"
              class="btn---cta with-gray-shadow btn-round btn-bold"
            >
              <span>Preview</span>
            </button>

            <Button
              v-if="getCustomWidgetAdd.id"
              id="preview-button"
              type="button"
              class="text-white bg-[#2560D7] !py-[1.142rem] !rounded-[0.571rem] hover:bg-blue-700"
              buttonClass="btn-lg"
              @click.prevent="previewWidget([getCustomWidgetAdd.id])"
            >
              <template v-slot:label>Preview</template>
            </Button>

            <Button
              id="store-button"
              type="button"
              class="text-white bg-[#2560D7] !py-[1.142rem] !rounded-[0.571rem] hover:bg-blue-700"
              buttonClass="btn-lg"
              @click.prevent="addWidget()"
              :disabledProp="getCustomWidgetAdd.addCustomLoader"
            >
              <template v-slot:label>{{
                  getCustomWidgetAdd._id ? "Update" : "Save"
                }}
              </template>
              <template v-if="getCustomWidgetAdd.addCustomLoader" v-slot:loader>
                <Loader></Loader>
              </template>
            </Button>
          </template>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import CustomWidgetAddForm from "../forms/CustomWidgetAddForm";
import {
  widgetCMOptions,
  widgetMessages,
  widgetValidations,
  validLength,
} from "../../../../../common/attributes";

export default {
  components: {
    CustomWidgetAddForm,
    Selectdropdown: () => import("@/ui/ui-kit/v2/Selectdropdown.vue"),
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    FloatingLabelInput: () => import("@/ui/ui-kit/v2/FloatingLabelInput.vue"),
    InputFieldMessage: () => import("@/ui/ui-kit/v2/InputFieldMessage.vue"),
    Checkbox: () => import("@/ui/ui-kit/v2/Checkbox.vue"),
  },
  data() {
    return {
      cmOptions: widgetCMOptions,
      checked: false,
      validations: widgetValidations,
      messages: widgetMessages,
      loader: true,
      validLength: validLength,
      localTriggerType: [],
      CheckBoxOne: true,
      CheckBoxTwo: false,
      positionsList: [
        {value: "bottom_left", label: "Bottom Left"},
        {value: "bottom_right", label: "Bottom Right"},
        {value: "middle", label: "Middle"},
        {value: "top_left", label: "Top Left"},
        {value: "top_right", label: "Top Right"},
      ],
    };
  },
  created() {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  },
  methods: {
    ...mapActions(["previewWidget"]),

    checkCheckBoxes(value) {
      console.log(value);
      if (value === "CheckBoxOne") {
        this.CheckBoxTwo = false;
      } else if (value === "CheckBoxTwo") {
        this.CheckBoxOne = false;
      }
      if (!(this.CheckBoxOne || this.CheckBoxTwo)) {
        console.log("gee true kr rha")
        console.log(this.CheckBoxOne)
        this.CheckBoxOne = true;
      }
      this.getCustomWidgetAdd.trigger_type = this.CheckBoxOne ? "exit_intent" : "time";

    },

    async addWidget() {
      this.validations.name = this.requiredCheck(this.getCustomWidgetAdd.name);
      this.validations.javascript = this.requiredCheck(
        this.getCustomWidgetAdd.js
      );
      this.validations.html = this.requiredCheck(this.getCustomWidgetAdd.html);

      if (!this.requiredCheck(this.getCustomWidgetAdd.js)) {
        this.validations.html = false;
      }

      if (!this.requiredCheck(this.getCustomWidgetAdd.html)) {
        this.validations.javascript = false;
      }

      let result = Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      );
      if (result) {
        await this.$store.dispatch("storeWidget");
        this.close();
      }
    },
    close() {
      this.$parent.closeModal();
      this.$bvModal.hide("customCode");
    },
  },
  computed: {
    ...mapGetters(["getCustomWidgetAdd"]),
  },
  mounted() {
    setTimeout(() => {
      this.$refs.cssCode.refresh();
      this.$refs.scriptCode.refresh();
    }, 1000);
  },
  watch: {
    //   localTriggerType: {
    //   handler(newValue, oldValue) {
    //     if (newValue.length === 0) {
    //       // If no checkboxes are selected, reselect the first one
    //       this.localTriggerType = ['exit_intent'];
    //     } else {
    //       // Keep only the last selected checkbox
    //       this.localTriggerType = [newValue[newValue.length - 1]];
    //     }
    //   },
    //   deep: true, // Watch changes deeply in the array
    // },
    "getCustomWidgetAdd.name"(value) {
      if (value && value.length > 0) {
        this.validations.name = false;
        this.validations.nameLength = !this.maxLength(value, 35);
      }
    },
    "getCustomWidgetAdd.js"(value) {
      if (value && value.length > 0) {
        this.validations.javascriptLength = !this.maxLength(
          value,
          this.validLength.custom_widget
        );
      }
      if (!this.validations.javascriptLength || !this.validations.html) {
        this.validations.javascript = false;
        this.validations.html = false;
      } else {
        this.validations.javascript = true;
        this.validations.html = true;
      }
    },
    "getCustomWidgetAdd.html"(value) {
      if (value && value.length > 0) {
        this.validations.htmlLength = !this.maxLength(
          value,
          this.validLength.custom_widget
        );
      }
      if (!this.validations.htmlLength || !this.validations.javascript) {
        this.validations.html = false;
        this.validations.javascript = false;
      } else {
        this.validations.javascript = true;
        this.validations.html = true;
      }
    },
    "getCustomWidgetAdd.trigger_seconds"(value) {
      if (value < 0) this.$set(this.getCustomWidgetAdd, "trigger_seconds", 0);
    },
  },
};
</script>
<style lang="less">
.custom-code-input {
  font-size: medium !important;
  min-height: 10.85rem !important;
}

div.ace_line > span {
  color: white !important;
}
</style>
